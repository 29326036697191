.hero {

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.buttonTrans{
	background-color: rgb(53,103,147);
	color:white;
 border-width: 0;
	height:70;
	width:200;
	padding-bottom: 20px;
	padding-top:20px;
	padding-left:15px;
	padding-right:15px;
	border-radius: 5px;

}

.buttonTrans:hover{
	background-color: white;
	color:rgb(53,103,147);
}
.bigText{
	color:white;
	font-size: 55px;
	font-weight: bold;
}

.secondText{
	color:white;
	font-size: 17px;
	
}
.DigitalPictures32{
	display:none;
}
.bigText2{
	color:white;
	font-size: 55px;
	font-weight: bold;

}
.alNaserClass{
position: absolute;
justify-content: center;
width:80%;
margin-left:10%;
height:50%;
margin-top:10%
}
@media screen and (max-width: 767px) {
	.alNaserClass{
		margin-top: 100px;
	}
	.DigitalPictures{
		height:500px;
		display:none;
	}
.onlyOnce{
	display: none;
}
	.DigitalPictures32{
		display:flex
	}
	.bigText{
		
		color:white;
		font-size: 35px;
		font-weight: bold;
	}
	.bigText2{
		margin-bottom: -20px;
		font-size:35px;
	}
	.secondText{
		color:white;
		font-size:15px
	}
	.buttonTrans{
		background-color:rgb(53,103,147);
		color:white;
	 border-width: 0;
		height:70;
		width:200;
		padding-bottom: 20px;
		padding-top:20px;
		padding-left:15px;
		padding-right:15px;
		border-radius: 5px;
	
	}
	
	.buttonTrans:hover{
		background-color: white;
		color:rgb(53,103,147);
	}
	
  }
  @media screen and (min-width: 768px) and (max-width:1000px){
	
	.alNaserClass{
		margin-top: 130px;
	}
	.DigitalPictures{
		height:500px;
		display:none;
	}
.onlyOnce{
	display: none;
}
	.DigitalPictures32{
		display:flex
	}
	.bigText{
		
		color:white;
		font-size: 48px;
		font-weight: bold;
	}
	.bigText2{
		margin-bottom: -20px;
		font-size:48px;
	}
	.secondText{
		color:white;
		font-size:20px
	}
	.buttonTrans{
		background-color:rgb(53,103,147);
		color:white;
	 border-width: 0;
		height:70;
		width:200;
		padding-bottom: 20px;
		padding-top:20px;
		padding-left:15px;
		padding-right:15px;
		border-radius: 5px;
	
	}
	
	.buttonTrans:hover{
		background-color: white;
		color:rgb(53,103,147);
	}
	
  }
.hero-inner {

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}
